import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState } from "react";
import client from "./api/client";

function App() {
  const [paraData, setParaData] = useState([]);
  const getData = async () => {
    const response = await client.get("/user/demoData");
    console.log(response);
    if (response.ok) {
      setParaData(response?.data?.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  console.log(paraData);
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>{"Test Envriornment is live!"}</p>
        {paraData?.map((item, index) => (
          <h1 key={index}>{item.message}</h1>
        ))}
        {/* <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
    </div>
  );
}

export default App;
